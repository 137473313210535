import React, { useState } from "react";
import "./products.css";

function Products() {
 
  const [selectedType, setType] = useState();

  const handleButtonClick = (type) => {
      setType(type)
  }

  const productTypes = [
    {
      id: 1,
      text: "Havuz Mozaikleri",
      image: require("../../assets/images/Products/p1.jpg"),
      type: "mosaics",
    },
    {
      id: 2,
      text: "Havuz Panoları",
      image: require("../../assets/images/Products/p2.jpg"),
      type: "patterns",
    },
    {
      id: 3,
      text: "Havuz Tutamak ve Kaymazları",
      image: require("../../assets/images/Products/p3.jpg"),
      type: "handles",
    },
    {
      id: 4,
      text: "Pano Uygulamaları",
      image: require("../../assets/images/Products/p4.jpg"),
      type: "uygulamalar",
    },
    {
      id: 5,
      text: "Havuz Porselen Seramikleri",
      image: require("../../assets/images/Products/p5.jpg"),
      type: "seramikler",
    },
  ];

  return (
    <div className="products d-flex justify-content-center flex-column align-items-center">
      <h1>Ürünler</h1>
      <div className="pro-cards d-flex flex-row justify-content-center">
        {productTypes.map((p, i) => (
          <a key={i} href={"/product/"+ selectedType}>
            <div onClick={(e) => handleButtonClick(p.type)} className="pro" >
              <div className="pro-top">
                <img src={p.image} alt="card-img" />
              </div>
              <div className="pro-bottom">
                <p>{p.text}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Products;
