import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "./navbar.css";

function NavBar({ section1Ref, section2Ref, section3Ref, section4Ref  }) {
  const handleSection1Click = () => {
    section1Ref.current.scrollIntoView();
  };

  const handleSection2Click = () => {
    section2Ref.current.scrollIntoView();
  };

  const handleSection3Click = () => {
    section3Ref.current.scrollIntoView();
  };

  const handleSection4Click = () => {
    section4Ref.current.scrollIntoView();
  };


  const handleToggle = () => {

  }
  return (
    <div className="fixed-top scrolling-text-container">
        <Navbar
          collapseOnSelect
          expand="sm"
          className="mynavbar"
          fixed="top"
          variant="dark"
        >
          <Container>
            <Navbar.Brand href="/">
              <img
                alt="logo"
                src={require("../../assets/images/logo.png")}
                className="navlogo d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => handleToggle()} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link className="nav-link" onClick={handleSection1Click}>
                  ANA SAYFA
                </Nav.Link>
                <Nav.Link className="nav-link" onClick={handleSection2Click}>
                  ÜRÜNLER
                </Nav.Link>
                <Nav.Link className="nav-link" onClick={handleSection3Click}>
                  İLETİŞİM
                </Nav.Link>
                <Nav.Link className="nav-link" onClick={handleSection4Click}>
                  HAKKIMIZDA
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      <div className="scrolling-text">
        <span>KAMPANYALI FİYATLAR İÇİN TEKLİF ALIN</span>
      </div>

      </div>

      

  );
}

export default NavBar;
