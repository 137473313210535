import React from 'react'
import ProductCard from "../ProductCard/ProductCard";

function Seramikler() {

  const seramik = [
    {id:1, name:"30*60 1 NO", imagePath:require("../../assets/images/Seramikler/1no.jpg")},
    {id:2, name:"30*60 2 NO", imagePath:require("../../assets/images/Seramikler/2no.jpg")},
    {id:3, name:"30*60 3 NO", imagePath:require("../../assets/images/Seramikler/3no.jpg")},
    {id:4, name:"30*60 4 NO", imagePath:require("../../assets/images/Seramikler/4no.jpg")},
    {id:5, name:"30*60 5 NO", imagePath:require("../../assets/images/Seramikler/5no.jpg")},
  ]


  return (
    <div className="uygulama-container images d-flex flex-row justify-content-center align-items-center">
       {seramik.map((u, i) => (
          <ProductCard
            key={i}
            imgSrc={u.imagePath}
            title={u.name}
            text={u.id}
          />
        ))}
    </div>
  )
}

export default Seramikler