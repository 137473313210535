import React from 'react'
import { Carousel } from 'react-bootstrap';
import './Carousel.css';

function CarouselComponent() {
    return (
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={require("../../assets/images/CarouselImages/c1.jpg")}
              alt="C1"
            />
            <Carousel.Caption className="carousel-caption">
              <p>Hoş Geldiniz</p>
              <p>Havuz Uygulamalarında Kaliteli ve Ekonomik Çözümler</p>
              <p>İletişim İçin <p className='phone-number'>+90 537 313 66 50</p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={require("../../assets/images/CarouselImages/c2.jpg")}
              alt="Second slide"
            />
            <Carousel.Caption className="carousel-caption">
              <p>Kaliteli ve Ekonomik Çözümler</p>
              <p>Havuz Uygulamarında ANTBAY Farkını Keşfedin</p>
              <p>İletişim İçin <p className='phone-number'>+90 537 313 66 50</p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={require("../../assets/images/CarouselImages/c3.jpg")}
              alt="Third slide"
            />
            <Carousel.Caption className="carousel-caption">
              <p>Havuz Uygulamalarında</p>
              <p>Kaliteli ve Ekonomik Çözümler için <p className='antbay'>Antbay Cam Mozaik</p></p>
              <p>İletişim İçin <p className='phone-number'>+90 537 313 66 50</p></p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      );
    }

export default CarouselComponent