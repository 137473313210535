import React, { useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import Pagination from "react-bootstrap/Pagination";

function Handles() {
  const handle = [
    {
      id: 1,
      name: "12x25 cm Pötikare Tutamak",
      imagePath: require("../../assets/images/Tutamak/1.jpg"),
    },
    {
      id: 2,
      name: "12x25 cm Pötikare Tutamak Sırsız",
      imagePath: require("../../assets/images/Tutamak/2.jpg"),
    },
    {
      id: 3,
      name: "12x25 cm Pötikare Tutamak Açık Mavi",
      imagePath: require("../../assets/images/Tutamak/3.jpg"),
    },
    {
      id: 4,
      name: "25x25 cm Pötikare Tutamak",
      imagePath: require("../../assets/images/Tutamak/4.jpg"),
    },
    {
      id: 5,
      name: "25x25 cm Pötikare Tutamak Sırsız",
      imagePath: require("../../assets/images/Tutamak/5.jpg"),
    },
    {
      id: 6,
      name: "25x25 cm Pötikare Tutamak Açık Mavi",
      imagePath: require("../../assets/images/Tutamak/6.jpg"),
    },
    {
      id: 7,
      name: "25x25 cm Pötikare Profilli Tutamak",
      imagePath: require("../../assets/images/Tutamak/7.jpg"),
    },
    {
      id: 8,
      name: "12x25 cm Pötikare Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/8.jpg"),
    },
    {
      id: 9,
      name: "12x25 cm Pötikare Oluklu Tutamak Sırsız",
      imagePath: require("../../assets/images/Tutamak/9.jpg"),
    },
    {
      id: 10,
      name: "25x25 cm Pötikare Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/10.jpg"),
    },
    {
      id: 11,
      name: "25x25 cm Pötikare Profilli Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/11.jpg"),
    },
    {
      id: 12,
      name: "12x25 cm Taş Desen Tutamak",
      imagePath: require("../../assets/images/Tutamak/12.jpg"),
    },
    {
      id: 13,
      name: "12x25 cm Taş Desen Tutamak Sırsız",
      imagePath: require("../../assets/images/Tutamak/13.jpg"),
    },
    {
      id: 14,
      name: "25x25 cm Taş Desen Tutamak",
      imagePath: require("../../assets/images/Tutamak/14.jpg"),
    },
    {
      id: 15,
      name: "12x25 cm Taş Desen Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/15.jpg"),
    },
    {
      id: 16,
      name: "25x25 cm Taş Desen Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/16.jpg"),
    },
    {
      id: 17,
      name: "25x30 cm Pötikare Tutamak",
      imagePath: require("../../assets/images/Tutamak/17.jpg"),
    },
    {
      id: 18,
      name: "25x30 cm Pötikare Profilli Tutamak",
      imagePath: require("../../assets/images/Tutamak/18.jpg"),
    },
    {
      id: 19,
      name: "30x30 cm Pötikare Tutamak",
      imagePath: require("../../assets/images/Tutamak/19.jpg"),
    },
    {
      id: 20,
      name: "25x30 cm Pötikare Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/20.jpg"),
    },
    {
      id: 21,
      name: "25x30 cm Pötikare Profilli Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/21.jpg"),
    },
    {
      id: 22,
      name: "25x30 cm Pötikare Profilli Tutamak",
      imagePath: require("../../assets/images/Tutamak/22.jpg"),
    },
    {
      id: 23,
      name: "12x25 cm Çizgili Tutamak",
      imagePath: require("../../assets/images/Tutamak/23.jpg"),
    },
    {
      id: 24,
      name: "12x25 cm Pötikare Çift Profilli Kaymaz",
      imagePath: require("../../assets/images/Tutamak/24.jpg"),
    },
    {
      id: 25,
      name: "12x25 cm Çizgili Kaymaz",
      imagePath: require("../../assets/images/Tutamak/25.jpg"),
    },
    {
      id: 26,
      name: "30x30 cm Pötikare Oluklu Tutamak",
      imagePath: require("../../assets/images/Tutamak/26.jpg"),
    },
    {
      id: 27,
      name: "25x25 Pötikare Çift Profilli Kaymaz",
      imagePath: require("../../assets/images/Tutamak/27.jpg"),
    },
    {
      id: 28,
      name: "25x25 Pötikare Antrasit Tutamak",
      imagePath: require("../../assets/images/Tutamak/28.jpg"),
    },
    {
      id: 29,
      name: "25x25 Pötikare Profilli Kaymaz",
      imagePath: require("../../assets/images/Tutamak/29.jpg"),
    },
    {
      id: 30,
      name: "12x25x0.9 cm Pötikare İnce Kaymaz",
      imagePath: require("../../assets/images/Tutamak/30.jpg"),
    },
    {
      id: 31,
      name: "12x25 Pötikare Profilli Kaymaz",
      imagePath: require("../../assets/images/Tutamak/31.jpg"),
    },
    {
      id: 32,
      name: "12x25x0.9 cm İnce Taş Desen Kaymaz",
      imagePath: require("../../assets/images/Tutamak/32.jpg"),
    },
    {
      id: 33,
      name: "25x25 Pötikare Pahlı Kaymaz",
      imagePath: require("../../assets/images/Tutamak/33.jpg"),
    },
    {
      id: 34,
      name: "12x25 Pötikare Pahlı Kaymaz Kobalt Bant",
      imagePath: require("../../assets/images/Tutamak/34.jpg"),
    },
    {
      id: 35,
      name: "12x25 Pötikare Pahlı Kaymaz Kobalt-Açık Mavi",
      imagePath: require("../../assets/images/Tutamak/35.jpg"),
    },
    {
      id: 36,
      name: "25x25 Pötikare İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/36.jpg"),
    },
    {
      id: 37,
      name: "25x25 Pötikare Profilli İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/37.jpg"),
    },
    {
      id: 38,
      name: "25x25 Pötikare Profilli İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/38.jpg"),
    },
    {
      id: 39,
      name: "25x25 Taş Desen İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/39.jpg"),
    },
    {
      id: 40,
      name: "25x25 Pötikare Dış Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/40.jpg"),
    },
    {
      id: 41,
      name: "25x25 Pötikare Profilli Dış Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/41.jpg"),
    },
    {
      id: 42,
      name: "25x25 Taş Desen Dış Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/42.jpg"),
    },
    {
      id: 43,
      name: "12x12 cm Pötikare İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/43.jpg"),
    },
    {
      id: 44,
      name: "12x12 cm Taş Desen İç Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/44.jpg"),
    },
    {
      id: 45,
      name: "12x12 cm Pötikare Profilli İç Köşe Kaymaz",
      imagePath: require("../../assets/images/Tutamak/45.jpg"),
    },
    {
      id: 46,
      name: "12x12 cm Pötikare Dış Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/46.jpg"),
    },
    {
      id: 47,
      name: "12x12 cm Taş Desen Dış Köşe Tutamak",
      imagePath: require("../../assets/images/Tutamak/47.jpg"),
    },
    {
      id: 48,
      name: "12x12 cm Pötikare Dış Köşe Kaymaz Profilli",
      imagePath: require("../../assets/images/Tutamak/48.jpg"),
    },
    {
      id: 49,
      name: "12x12x0.9 cm Pötikare İç/Dış Köşe Kaymaz",
      imagePath: require("../../assets/images/Tutamak/49.jpg"),
    },
    {
      id: 50,
      name: "12x12x0.9 cm Pötikare İç/Dış Köşe Kaymaz",
      imagePath: require("../../assets/images/Tutamak/50.jpg"),
    },
    {
      id: 51,
      name: "12x12x0.9 cm Taş Desen İç/Dış Köşe Kaymaz",
      imagePath: require("../../assets/images/Tutamak/51.jpg"),
    },
    {
      id: 52,
      name: "12x12x0.9 cm Taş Desen İç/Dış Köşe Kaymaz",
      imagePath: require("../../assets/images/Tutamak/52.jpg"),
    },
    {
      id: 53,
      name: "25x25 cm Pötikare Tutamak Üzeri Rakam Yazı",
      imagePath: require("../../assets/images/Tutamak/53.jpg"),
    },
    {
      id: 54,
      name: "12x12x0.9 cm Pötikare Tutamak Üzeri Rakam Yazı",
      imagePath: require("../../assets/images/Tutamak/54.jpg"),
    },
    {
      id: 55,
      name: "12x12x0.9 cm Pötikare Kaymaz Üzeri Yön Oku",
      imagePath: require("../../assets/images/Tutamak/55.jpg"),
    },
    {
      id: 56,
      name: "4x24.5x5 cm İç Bükey Köşebent",
      imagePath: require("../../assets/images/Tutamak/56.jpg"),
    },
    {
      id: 57,
      name: "4x24.5x5 cm Dış Bükey Köşebent",
      imagePath: require("../../assets/images/Tutamak/57.jpg"),
    },
    {
      id: 58,
      name: "4x24.5x5 cm Dış Bükey Köşebent Açık Mavi",
      imagePath: require("../../assets/images/Tutamak/58.jpg"),
    },
    {
      id: 59,
      name: "4x24.5x5 cm Dış Bükey Köşebent Bej",
      imagePath: require("../../assets/images/Tutamak/59.jpg"),
    },
    {
      id: 60,
      name: "4x24.5x5 cm Dış Bükey Köşebent Yeşil",
      imagePath: require("../../assets/images/Tutamak/60.jpg"),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = handle.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(handle.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="handle-container items d-flex flex-column align-items-center">
      <div className="images d-flex flex-row justify-content-center">
        {currentItems.map((m, i) => (
          <ProductCard
            key={i}
            imgSrc={m.imagePath}
            title={m.name}
            text={m.id}
          />
        ))}
      </div>
      <div className="page-numbers">
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
}

export default Handles;
