import React from "react";
import './WhatsappContact.css'

function WhatsappContact() {
  return (
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=905373136650"
        className="whatsapp-button d-flex justify-content-center align-items-center"
      >
        <i className="fa fa-whatsapp"></i>
      </a>
    </div>
  );
}

export default WhatsappContact;
