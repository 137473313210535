import React, { useRef } from "react";
import About from "../../components/About/About";
import CarouselComponent from "../../components/CarouselComponent/CarouselComponent";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/Navbar";
import Products from "../../components/Products/Products";
import WhatsappContact from "../../components/WhatsappContact/WhatsappContact";

import "./main.css";

function Main() {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  return (
    <div className="Main">
      {/* Navigation Bar */}
      <NavBar
        section1Ref={section1Ref}
        section2Ref={section2Ref}
        section3Ref={section3Ref}
        section4Ref={section4Ref}
      />
      {/* Main Container */}
      <div className="main-container d-flex flex-column align-items-center">
        <WhatsappContact />
        {/* Carousel Container */}

        <section ref={section1Ref} className="carousel-container">
          <CarouselComponent />
        </section>

        {/* Products Container */}
        <section
          ref={section2Ref}
          className="products-container d-flex justify-content-center align-items-center"
        >
          <Products />
        </section>
        <div className="line" />
        {/* Contact Container */}
        <section
          ref={section3Ref}
          className="contact-container d-flex flex-column justify-content-center align-items-center"
        >
          <Contact />
        </section>
        <div className="line" />

        <section ref={section4Ref} className="about-container d-flex flex-column justify-content-center align-items-center">
          <About />
        </section>
        {/* Footer */}

        <Footer />
      </div>
    </div>
  );
}

export default Main;
