import React from 'react'
import ProductCard from "../ProductCard/ProductCard";

function Uygulamalar() {

  const uygulama = [
    {id:1, name:"1", imagePath:require("../../assets/images/Uygulamalar/1.jpg")},
    {id:2, name:"2", imagePath:require("../../assets/images/Uygulamalar/2.jpg")},
    {id:3, name:"3", imagePath:require("../../assets/images/Uygulamalar/3.jpg")},
    {id:4, name:"4", imagePath:require("../../assets/images/Uygulamalar/4.jpg")},
    {id:5, name:"5", imagePath:require("../../assets/images/Uygulamalar/5.jpg")},
    {id:6, name:"6", imagePath:require("../../assets/images/Uygulamalar/6.jpg")},
    {id:7, name:"7", imagePath:require("../../assets/images/Uygulamalar/7.jpg")},
    {id:8, name:"8", imagePath:require("../../assets/images/Uygulamalar/8.jpg")},
    {id:9, name:"9", imagePath:require("../../assets/images/Uygulamalar/9.jpg")},
    {id:10, name:"10", imagePath:require("../../assets/images/Uygulamalar/10.jpg")},
    {id:11, name:"11", imagePath:require("../../assets/images/Uygulamalar/11.jpg")},
  ]


  return (
    <div className="uygulama-container images d-flex flex-row justify-content-center align-items-center">
       {uygulama.map((u, i) => (
          <ProductCard
            key={i}
            imgSrc={u.imagePath}
            title={u.name}
            text={u.id}
          />
        ))}
    </div>
  )
}

export default Uygulamalar