import React from "react";
import NavBar from "../../components/NavBar/Navbar";
import Mosaics from "../../components/Products/Mosaics";
import Handles from "../../components/Products/Handles";
import Patterns from "../../components/Products/Patterns";
import Footer from "../../components/Footer/Footer";
import WhatsappContact from "../../components/WhatsappContact/WhatsappContact";
import "./product.css";
import Uygulamalar from "../../components/Products/Uygulamalar";
import Seramikler from "../../components/Products/Seramikler";

function Product() {
  const url = window.location.href;
  const lastSlashIndex = url.lastIndexOf("/");
  const endOfUrl = url.slice(lastSlashIndex + 1);

  return (
    <div className="Product">
      <NavBar />
      <div className="products-page-container">
      <WhatsappContact />
      <div className="product-info d-flex justify-content-center">
        <p>Ürünler hakkında bilgi almak için iletişime geçiniz +90 537 313 66 50</p>
      </div>
        {endOfUrl === "mosaics" && <Mosaics />}
        {endOfUrl === "patterns" && <Patterns />}
        {endOfUrl === "handles" && <Handles />}
        {endOfUrl === "uygulamalar" && <Uygulamalar/>}
        {endOfUrl === "seramikler" && <Seramikler />}
      </div>
      <Footer />
    </div>
  );
}

export default Product;
