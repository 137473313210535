import React from "react";
import "./about.css";

function About() {
  return (
    <div className="About d-flex flex-column align-items-center text-center">
        <h1>Hakkımızda</h1>
        <div className="about-text">
          <p>
            Firmamız 2004 yılında faaliyete geçmiş bir aile kuruluşudur. Cam
            Mozaik sektöründeki bilgi ve tecrübemize dayanarak havuz kaplaması
            cammozaiklerin ithalatını yapıyoruz. Cam mozaiklerimiz Uluslararası
            Kalite Belgelerine sahiptir. Ayrıca yurt içinde faaliyet gösteren
            TSEK Kalite Belgeli üreticilerin de havuza özel cam mozaik
            ürünlerinin pazarlamasını yapmaktayız. Porselen Tutamak ve Kaymaz,
            Havuz Ekipmanları, Havuz Kimyasallarının tedarik ve pazarlaması da
            diğer faaliyetlerimiz arasındadır. Bilgisayar ortamında oluşturulan
            standart havuz desen ve bordürlerinin yanında kişiye özel desen,
            logo, v.s. çalışmalarımız ile havuzlarda farklılıklar yaratıyoruz.
            Amacımız kaliteli ürünleri, siz sayın müşterilerimize en uygun
            fiyatla sunmak ve memnuniyetinizi sağlamaktır. ANTBAY İTHALAT olarak
            hedefimiz bu sektördeki dürüst ve ilkeli duruşumuzla siz değerli
            müşterilerimizin güvenini kaybetmemektir.
          </p>
        </div>
    </div>
  );
}

export default About;
