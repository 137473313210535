import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

function ProductCard(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Card
        onClick={(e) => handleShow()}
        className="text-center"
        style={{ width: "18rem" }}
      >
        <Card.Img variant="top" src={props.imgSrc} />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
        </Card.Body>
      </Card>

      <Modal
        animation={true}
        centered
        show={show}
        onHide={handleClose}
      >
        <img src={props.imgSrc} alt={props.name} />
      </Modal>
    </div>
  );
}

export default ProductCard;
