import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Product from "./pages/Product";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/product/mosaics" element={<Product />} />
        <Route path="/product/patterns" element={<Product />} />
        <Route path="/product/handles" element={<Product />} />
        <Route path="/product/uygulamalar" element={<Product />} />
        <Route path="/product/seramikler" element={<Product />} />
      </Routes>
    </div>
  );
}

export default App;
