import React from 'react'
import ProductCard from "../ProductCard/ProductCard";

function Patterns() {

  const pattern = [
    {id:1, name:"1", imagePath:require("../../assets/images/Pano/desen_1.png")},
    {id:2, name:"2", imagePath:require("../../assets/images/Pano/desen_2.png")},
    {id:3, name:"3", imagePath:require("../../assets/images/Pano/desen_3.png")},
    {id:4, name:"4", imagePath:require("../../assets/images/Pano/desen_4.png")},
    {id:5, name:"5", imagePath:require("../../assets/images/Pano/desen_5.png")},
    {id:6, name:"6", imagePath:require("../../assets/images/Pano/desen_6.png")},
  ]


  return (
    <div className="pattern-container images d-flex flex-row justify-content-center align-items-center">
       {pattern.map((p, i) => (
          <ProductCard
            key={i}
            imgSrc={p.imagePath}
            title={p.name}
            text={p.id}
          />
        ))}
    </div>
  )
}

export default Patterns