import React from "react";
import "./contact.css";

function Contact() {
  return (
    <div className="contact d-flex flex-column justify-content-center align-items-center">
      <h1>İletişim</h1>
      <div className="google-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12041.648862231677!2d29.1234578!3d41.0162367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9661f8b2580c533f!2sAntbay%20%C4%B0thalat!5e0!3m2!1str!2str!4v1675002345054!5m2!1str!2str"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="harita"
      ></iframe>
      </div>
   

      <div className="contact-info d-flex justify-content-center flex-wrap">
        <div className="info d-flex flex-column">
          <div className="info-title">Adres</div>
          <div className="info-text">
            Çakmak, Varan Cd. NO;43/A, 34770 Ümraniye/İstanbul
          </div>
        </div>
        <div className="info d-flex flex-column">
          <div className="info-title">Çalışma Saatleri</div>
          <div className="info-text">Hafta İçi: 08.00 - 17.00</div>
        </div>
        <div className="info d-flex flex-column">
          <div className="info-title">Bize Ulaşın</div>
          <div className="info-text">
            <p>Tel: +90 537 313 66 50</p>
            <p>Mail: varolcelikler@hotmail.com</p>
          </div>
        </div>
      </div>

      <div className="social-media d-flex">
        <a
          className="facebook social d-flex justify-content-center align-items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/profile.php?id=100004670026598"
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          className="instagram social d-flex justify-content-center align-items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/antbaymozaik/"
        >
          <i className="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  );
}

export default Contact;
