import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import './footer.css'

function Footer() {
  return (
    <Navbar className="customfooter">
      <Container>
        <Nav>Copyright © 2023 . All Rights Reserved.</Nav>
        <Nav>Built by<a className="me" href="https://onurcelikler.netlify.app" target="_blank" rel="noopener noreferrer"> Onur Çelikler</a></Nav>
      </Container>
    </Navbar>
  );
}

export default Footer;
