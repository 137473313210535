import React, { useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import Pagination from "react-bootstrap/Pagination";
import Button from "react-bootstrap/Button";

function Mosaics() {
  const mosaic = [
    {
      id: 1,
      name: "ANT-01",
      imagePath: require("../../assets/images/ANT/ANT-01.jpg"),
    },
    {
      id: 2,
      name: "ANT-02",
      imagePath: require("../../assets/images/ANT/ANT-02.jpg"),
    },
    {
      id: 3,
      name: "ANT-03",
      imagePath: require("../../assets/images/ANT/ANT-03.jpg"),
    },
    {
      id: 4,
      name: "ANT-04",
      imagePath: require("../../assets/images/ANT/ANT-04.jpg"),
    },
    {
      id: 5,
      name: "ANT-05",
      imagePath: require("../../assets/images/ANT/ANT-05.jpg"),
    },
    {
      id: 6,
      name: "ANT-06",
      imagePath: require("../../assets/images/ANT/ANT-06.jpg"),
    },
    {
      id: 7,
      name: "ANT-07",
      imagePath: require("../../assets/images/ANT/ANT-07.jpg"),
    },
    {
      id: 8,
      name: "ANT-08",
      imagePath: require("../../assets/images/ANT/ANT-08.jpg"),
    },
    {
      id: 9,
      name: "ANT-09",
      imagePath: require("../../assets/images/ANT/ANT-09.jpg"),
    },
    {
      id: 10,
      name: "ANT-10",
      imagePath: require("../../assets/images/ANT/ANT-10.jpg"),
    },
    {
      id: 11,
      name: "ANT-11",
      imagePath: require("../../assets/images/ANT/ANT-11.jpg"),
    },
    {
      id: 12,
      name: "ANT-12",
      imagePath: require("../../assets/images/ANT/ANT-12.jpg"),
    },
    {
      id: 13,
      name: "ANT-13",
      imagePath: require("../../assets/images/ANT/ANT-13.jpg"),
    },
    {
      id: 14,
      name: "ANT-14",
      imagePath: require("../../assets/images/ANT/ANT-14.jpg"),
    },
    {
      id: 15,
      name: "ANT-15",
      imagePath: require("../../assets/images/ANT/ANT-15.jpg"),
    },
    {
      id: 16,
      name: "ANT-16",
      imagePath: require("../../assets/images/ANT/ANT-16.jpg"),
    },
    {
      id: 17,
      name: "ANT-17",
      imagePath: require("../../assets/images/ANT/ANT-17.jpg"),
    },
    {
      id: 18,
      name: "ANT-18",
      imagePath: require("../../assets/images/ANT/ANT-18.jpg"),
    },
    {
      id: 19,
      name: "ANT-19",
      imagePath: require("../../assets/images/ANT/ANT-19.jpg"),
    },
    {
      id: 20,
      name: "ANT-20",
      imagePath: require("../../assets/images/ANT/ANT-20.jpg"),
    },
    {
      id: 21,
      name: "ANT-21",
      imagePath: require("../../assets/images/ANT/ANT-21.jpg"),
    },
    {
      id: 22,
      name: "ANT-22",
      imagePath: require("../../assets/images/ANT/ANT-22.jpg"),
    },
    {
      id: 23,
      name: "ANT-23",
      imagePath: require("../../assets/images/ANT/ANT-23.jpg"),
    },
    {
      id: 24,
      name: "ANT-24",
      imagePath: require("../../assets/images/ANT/ANT-24.jpg"),
    },
    {
      id: 25,
      name: "ANT-25",
      imagePath: require("../../assets/images/ANT/ANT-25.jpg"),
    },
    {
      id: 26,
      name: "ANT-26",
      imagePath: require("../../assets/images/ANT/ANT-26.jpg"),
    },
    {
      id: 27,
      name: "ANT-27",
      imagePath: require("../../assets/images/ANT/ANT-27.jpg"),
    },
    {
      id: 28,
      name: "ANT-28",
      imagePath: require("../../assets/images/ANT/ANT-28.jpg"),
    },
    {
      id: 29,
      name: "ANT-29",
      imagePath: require("../../assets/images/ANT/ANT-29.jpg"),
    },
    {
      id: 30,
      name: "ANT-30",
      imagePath: require("../../assets/images/ANT/ANT-30.jpg"),
    },
    {
      id: 31,
      name: "ANT-31",
      imagePath: require("../../assets/images/ANT/ANT-31.jpg"),
    },
    {
      id: 32,
      name: "ANT-32",
      imagePath: require("../../assets/images/ANT/ANT-32.jpg"),
    },
    {
      id: 33,
      name: "ANT-33",
      imagePath: require("../../assets/images/ANT/ANT-33.jpg"),
    },
    {
      id: 34,
      name: "ANT-34",
      imagePath: require("../../assets/images/ANT/ANT-34.jpg"),
    },
    {
      id: 35,
      name: "ANT-35",
      imagePath: require("../../assets/images/ANT/ANT-35.jpg"),
    },
    {
      id: 36,
      name: "ANT-36",
      imagePath: require("../../assets/images/ANT/ANT-36.jpg"),
    },
    {
      id: 37,
      name: "ANT-37",
      imagePath: require("../../assets/images/ANT/ANT-37.jpg"),
    },
    {
      id: 38,
      name: "ANT-38",
      imagePath: require("../../assets/images/ANT/ANT-38.jpg"),
    },
    {
      id: 39,
      name: "ANT-39",
      imagePath: require("../../assets/images/ANT/ANT-39.jpg"),
    },
    {
      id: 40,
      name: "ANT-40",
      imagePath: require("../../assets/images/ANT/ANT-40.jpg"),
    },
    {
      id: 41,
      name: "ANT-41",
      imagePath: require("../../assets/images/ANT/ANT-41.jpg"),
    },
    {
      id: 42,
      name: "ANT-42",
      imagePath: require("../../assets/images/ANT/ANT-42.jpg"),
    },
    {
      id: 43,
      name: "ANT-43",
      imagePath: require("../../assets/images/ANT/ANT-43.jpg"),
    },
    {
      id: 44,
      name: "ANT-44",
      imagePath: require("../../assets/images/ANT/ANT-44.jpg"),
    },
    {
      id: 45,
      name: "ANT-45",
      imagePath: require("../../assets/images/ANT/ANT-45.jpg"),
    },
    {
      id: 46,
      name: "ANT-46",
      imagePath: require("../../assets/images/ANT/ANT-46.jpg"),
    },
    {
      id: 47,
      name: "ANT-47",
      imagePath: require("../../assets/images/ANT/ANT-47.jpg"),
    },
    {
      id: 48,
      name: "ANT-48",
      imagePath: require("../../assets/images/ANT/ANT-48.jpg"),
    },
    {
      id: 49,
      name: "ANT-49",
      imagePath: require("../../assets/images/ANT/ANT-49.jpg"),
    },
    {
      id: 50,
      name: "ANT-50",
      imagePath: require("../../assets/images/ANT/ANT-50.jpg"),
    },
    {
      id: 51,
      name: "ANT-51",
      imagePath: require("../../assets/images/ANT/ANT-51.jpg"),
    },
    {
      id: 52,
      name: "ANT-52",
      imagePath: require("../../assets/images/ANT/ANT-52.jpg"),
    },
    {
      id: 53,
      name: "ANT-53",
      imagePath: require("../../assets/images/ANT/ANT-53.jpg"),
    },
    {
      id: 54,
      name: "ANT-54",
      imagePath: require("../../assets/images/ANT/ANT-54.jpg"),
    },
    {
      id: 55,
      name: "ANT-55",
      imagePath: require("../../assets/images/ANT/ANT-55.jpg"),
    },
    {
      id: 56,
      name: "ANT-56",
      imagePath: require("../../assets/images/ANT/ANT-56.jpg"),
    },
    {
      id: 57,
      name: "ANT-57",
      imagePath: require("../../assets/images/ANT/ANT-57.jpg"),
    },
    {
      id: 58,
      name: "ANT-58",
      imagePath: require("../../assets/images/ANT/ANT-58.jpg"),
    },
    {
      id: 59,
      name: "ANT-59",
      imagePath: require("../../assets/images/ANT/ANT-59.jpg"),
    },
    {
      id: 60,
      name: "ANT-60",
      imagePath: require("../../assets/images/ANT/ANT-60.jpg"),
    },
    {
      id: 61,
      name: "ANT-61",
      imagePath: require("../../assets/images/ANT/ANT-61.jpg"),
    },
    {
      id: 62,
      name: "ANT-62",
      imagePath: require("../../assets/images/ANT/ANT-62.jpg"),
    },
    {
      id: 63,
      name: "ANT-63",
      imagePath: require("../../assets/images/ANT/ANT-63.jpg"),
    },
    {
      id: 64,
      name: "ANT-64",
      imagePath: require("../../assets/images/ANT/ANT-64.jpg"),
    },
    {
      id: 65,
      name: "ANT-65",
      imagePath: require("../../assets/images/ANT/ANT-65.jpg"),
    },
    {
      id: 66,
      name: "ANT-66",
      imagePath: require("../../assets/images/ANT/ANT-66.jpg"),
    },
    {
      id: 67,
      name: "ANT-67",
      imagePath: require("../../assets/images/ANT/ANT-67.jpg"),
    },
    {
      id: 68,
      name: "ANT-68",
      imagePath: require("../../assets/images/ANT/ANT-68.jpg"),
    },
    {
      id: 69,
      name: "ANT-69",
      imagePath: require("../../assets/images/ANT/ANT-69.jpg"),
    },
    {
      id: 70,
      name: "ANT-70",
      imagePath: require("../../assets/images/ANT/ANT-70.jpg"),
    },
    {
      id: 71,
      name: "ANT-71",
      imagePath: require("../../assets/images/ANT/ANT-71.jpg"),
    },
    {
      id: 72,
      name: "ANT-72",
      imagePath: require("../../assets/images/ANT/ANT-72.jpg"),
    },
    {
      id: 73,
      name: "ANT-73",
      imagePath: require("../../assets/images/ANT/ANT-73.jpg"),
    },
    {
      id: 74,
      name: "ANT-74",
      imagePath: require("../../assets/images/ANT/ANT-74.jpg"),
    },
    {
      id: 75,
      name: "ANT-75",
      imagePath: require("../../assets/images/ANT/ANT-75.jpg"),
    },
    {
      id: 76,
      name: "ANT-76",
      imagePath: require("../../assets/images/ANT/ANT-76.jpg"),
    },
  ];

  const fileli = [
    {
      id: 1,
      name: "Fileli NO 1",
      imagePath: require("../../assets/images/Fileli/No1.jpg"),
    },
    {
      id: 1,
      name: "Fileli NO 2",
      imagePath: require("../../assets/images/Fileli/No2.jpg"),
    },
    {
      id: 1,
      name: "Fileli NO 4",
      imagePath: require("../../assets/images/Fileli/No4.jpg"),
    },
  ]

  const [mosaicType, setMosaicType] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mosaic.slice(indexOfFirstItem, indexOfLastItem);

  const handleTypeChange = (type) => {
    setMosaicType(type);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(mosaic.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mosaic-container items d-flex flex-column align-items-center">
      <div className="type-buttons d-flex flex-row justify-content-between">
      <Button onClick={() => handleTypeChange(2)} variant="dark">
        EKONOMİK SERİ FİLELİ
      </Button>
      <Button onClick={() => handleTypeChange(1)} variant="dark">
        SİLYCORD SİSTEM
      </Button> 
      </div>
     
      <div className="images d-flex flex-row justify-content-center">
        {mosaicType === 1 &&
          currentItems.map((m, i) => (
            <ProductCard
              key={i}
              imgSrc={m.imagePath}
              title={m.name}
              text={m.id}
            />
          ))}
        {mosaicType === 2 &&
          fileli.map((m, i) => (
            <ProductCard
              key={i}
              imgSrc={m.imagePath}
              title={m.name}
              text={m.id}
            />
          ))}
      </div>
     {mosaicType === 1 && <div className="page-numbers">
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>}
    </div>
  );
}

export default Mosaics;
